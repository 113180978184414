import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '@components';
import { Statistics } from '@home/mainBanner/components';
import * as styles from './MainBanner.module.scss';

const MainBanner = ({ intl: { formatMessage } }) => {
  return (
    <section>
      <div className={styles.container_main_banner}>
        <div className={styles.container_main_banner__info}>
          <div className={styles.container_main_banner__info__matrix}>
            <StaticImage
              src="../../../../images/matrix.webp"
              alt="matrix"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
            />
          </div>
          <div className={styles.container_main_banner__info__content_text}>
            <h1 className={styles.container_main_banner__info__text_1}>
              {formatMessage({ id: 'lookingFor' })}{' '}
              <span className={styles.container_main_banner__info__text_2}>
                {formatMessage({ id: 'iTTalents' })}
              </span>{' '}
              {formatMessage({ id: 'forYourBusiness' })}?
            </h1>
            <div
              className={
                styles.container_main_banner__info__content_text__container_list
              }
            >
              <div
                className={
                  styles.container_main_banner__info__content_text__container_list__list
                }
              >
                <StaticImage
                  src="../../../../images/checked.webp"
                  width={24}
                  height={21}
                  alt="checked"
                  placeholder="blurred"
                  loading="lazy"
                  layout="constrained"
                  formats={['auto', 'webp', 'avif']}
                />
                <p className={styles.container_main_banner__info__subtitle}>
                  {formatMessage({ id: 'weProvideTalent' })}
                </p>
              </div>
              <div
                className={
                  styles.container_main_banner__info__content_text__container_list__list
                }
              >
                <StaticImage
                  src="../../../../images/checked.webp"
                  width={24}
                  height={21}
                  alt="checked"
                  placeholder="blurred"
                />
                <p className={styles.container_main_banner__info__subtitle}>
                  {formatMessage({ id: 'findExcellentProfessionals' })}
                </p>
              </div>
              <div className={styles.container_main_banner__content_btn}>
                <Button>
                  <p className="btn_text"> {formatMessage({ id: 'tellUs' })}</p>
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.container_main_banner__info__content_svg}>
            <StaticImage
              src="../../../../images/tellus.webp"
              alt="tellus"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
            />
          </div>
        </div>
      </div>
      <div className={styles.container_main_banner__content_img_networks}>
        <StaticImage
          src="../../../../images/group_reds.webp"
          className={styles.container_main_banner__img_networks}
          alt="group_reds"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
        />
      </div>
      <Statistics />
    </section>
  );
};

MainBanner.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(MainBanner);
