// extracted by mini-css-extract-plugin
export var background_images = "MainBanner-module__background_images___SPQR6";
export var body_background = "#f8f9fa";
export var card = "MainBanner-module__card___szQVS";
export var chip = "MainBanner-module__chip___Qk3Gs";
export var container = "MainBanner-module__container___RTDxf";
export var container_main_banner = "MainBanner-module__container_main_banner___StTQd";
export var container_main_banner__content_btn = "MainBanner-module__container_main_banner__content_btn___Nn85D";
export var container_main_banner__content_img_networks = "MainBanner-module__container_main_banner__content_img_networks___qegHd";
export var container_main_banner__img_networks = "MainBanner-module__container_main_banner__img_networks___gSbaE";
export var container_main_banner__info = "MainBanner-module__container_main_banner__info___BLFM3";
export var container_main_banner__info__content_btn = "MainBanner-module__container_main_banner__info__content_btn___GW1kb";
export var container_main_banner__info__content_svg = "MainBanner-module__container_main_banner__info__content_svg___1vqRy";
export var container_main_banner__info__content_text = "MainBanner-module__container_main_banner__info__content_text___w5aHo";
export var container_main_banner__info__content_text__container_list = "MainBanner-module__container_main_banner__info__content_text__container_list___EVCgq";
export var container_main_banner__info__content_text__container_list__list = "MainBanner-module__container_main_banner__info__content_text__container_list__list___uzbX8";
export var container_main_banner__info__matrix = "MainBanner-module__container_main_banner__info__matrix___1mWUP";
export var container_main_banner__info__subtitle = "MainBanner-module__container_main_banner__info__subtitle___MnO60";
export var container_main_banner__info__text_1 = "MainBanner-module__container_main_banner__info__text_1___pRu8p";
export var container_main_banner__info__text_2 = "MainBanner-module__container_main_banner__info__text_2___OJ-Fm";
export var content = "MainBanner-module__content___gcmsK";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "MainBanner-module__full_img___Oo5AR";
export var full_imgSmall = "MainBanner-module__full_img--small___IRHu+";
export var gray_21 = "MainBanner-module__gray_21___9gkE7";
export var image_container = "MainBanner-module__image_container___sRzns";
export var image_item = "MainBanner-module__image_item___a2dB8";
export var lg = "1200px";
export var logo = "MainBanner-module__logo___e26XW";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "MainBanner-module__primary___AgB1e";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "MainBanner-module__title___w3vVF";
export var typography_h1 = "MainBanner-module__typography_h1___v5bJ0";
export var typography_h2 = "MainBanner-module__typography_h2___wXtyC";
export var typography_h3 = "MainBanner-module__typography_h3___2uM73";
export var white = "#fff";
export var white_button = "MainBanner-module__white_button___LpjXs";
export var xl = "1536px";
export var xxl = "2500px";